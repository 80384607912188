.container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
}
.container1 {
  flex: 0 0 auto;
  width: 82vw;
  height: 18vh;
  display: flex;
  z-index: 1;
  position: relative;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.image {
  height: 2.5vw;
  object-fit: cover;
}
.image1 {
  width: 100px;
  display: none;
  object-fit: cover;
}
.container3 {
  flex: 0 0 auto;
  left: auto;
  right: 9.5vw;
  height: 2vw;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navlink {
  color: #ffffff;
  font-size: 2vh;
  align-self: center;
  font-style: normal;
  font-family: Circular Std Book;
  font-weight: 400;
  margin-right: 2.2vw;
  text-decoration: none;
}
.text {
  color: #1A062D;
}
.navlink1 {
  color: #1a062d;
  font-size: 2vh;
  align-self: center;
  font-style: normal;
  font-family: Circular Std Book;
  font-weight: 400;
  margin-right: 2.2vw;
  text-decoration: none;
}
.link {
  color: #1a062d;
  font-size: 2vh;
  align-self: center;
  font-style: normal;
  font-family: Circular Std Book;
  font-weight: 400;
  text-decoration: none;
}
.container4 {
  flex: 0 0 auto;
  right: 0px;
  width: 8vw;
  height: 2vw;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.link1 {
  display: contents;
}
.image2 {
  width: 100%;
  align-self: center;
  object-fit: cover;
  margin-right: 1.2vw;
  text-decoration: none;
}
.link2 {
  display: contents;
}
.image3 {
  width: 100%;
  object-fit: cover;
  margin-right: 1.2vw;
  text-decoration: none;
}
.link3 {
  display: contents;
}
.image4 {
  width: 100%;
  object-fit: cover;
  text-decoration: none;
}
.container5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.image5 {
  width: 100px;
  object-fit: cover;
}
@media(max-width: 479px) {
  .container1 {
    width: 82%;
    height: 12vh;
  }
  .image {
    height: 8vw;
  }
  .image1 {
    right: 0px;
    width: auto;
    height: 4vh;
    margin: auto;
    display: flex;
    position: absolute;
  }
  .container3 {
    display: none;
  }
  .container4 {
    display: none;
  }
}

.container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.container1 {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  background-color: #00ff7f;
  border-image-source: linear-gradient(93.84deg, #00FFE9 0%, #FFC365 51.97%, #5E3BE1 100%);
}

.container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #FAFBFC;
}
.image {
  top: 0px;
  right: 0px;
  width: 55vw;
  height: 100vh;
  margin: auto;
  z-index: 0;
  position: absolute;
  object-fit: cover;
}
.container1 {
  flex: 0 0 auto;
  width: 82vw;
  height: 18vh;
  display: flex;
  z-index: 1;
  position: relative;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.image1 {
  height: 2.5vw;
  object-fit: cover;
}
.image2 {
  width: 100px;
  display: none;
  object-fit: cover;
}
.container2 {
  flex: 0 0 auto;
  left: auto;
  right: 9.5vw;
  height: 2vw;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navlink {
  color: #ffffff;
  font-size: 2vh;
  align-self: center;
  font-style: normal;
  font-family: Circular Std Book;
  font-weight: 400;
  margin-right: 2.2vw;
  text-decoration: none;
}
.link {
  color: #ffffff;
  font-size: 2vh;
  align-self: center;
  font-style: normal;
  font-family: Circular Std Book;
  font-weight: 400;
  margin-right: 2.2vw;
  text-decoration: none;
}
.link1 {
  color: #ffffff;
  font-size: 2vh;
  align-self: center;
  font-style: normal;
  font-family: Circular Std Book;
  font-weight: 400;
  text-decoration: none;
}
.container3 {
  flex: 0 0 auto;
  right: 0px;
  width: 8vw;
  height: 2vw;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.link2 {
  display: contents;
}
.image3 {
  width: 100%;
  object-fit: cover;
  margin-right: 1.2vw;
  text-decoration: none;
}
.link3 {
  display: contents;
}
.image4 {
  width: 100%;
  object-fit: cover;
  margin-right: 1.2vw;
  text-decoration: none;
}
.link4 {
  display: contents;
}
.image5 {
  width: 100%;
  object-fit: cover;
  text-decoration: none;
}
.container4 {
  flex: 0 0 auto;
  width: 82vw;
  height: 4.5vh;
  display: flex;
  z-index: 1;
  align-self: center;
  margin-top: 5vh;
  align-items: center;
  flex-direction: row;
}
.container5 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.text {
  font-size: 4vh;
  font-style: normal;
  font-family: Circular Std Book;
  font-weight: 400;
}
.image6 {
  height: 6.2vh;
  object-fit: cover;
  margin-left: 0.6vw;
  margin-right: 0.6vw;
}
.text1 {
  font-size: 4vh;
  font-style: normal;
  font-family: Circular Std Book;
  font-weight: 400;
}
.container6 {
  flex: 0 0 auto;
  width: 82vw;
  display: flex;
  align-self: center;
  margin-top: 5vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.form {
  width: 30vw;
  align-self: flex-start;
}
.textinput {
  color: #000000;
  width: 30vw;
  font-size: 2vh;
  font-family: Circular Std Book;
  padding-top: 1.4em;
  border-color: #e6e7eb;
  border-width: 0.15vw;
  padding-left: 1.8em;
  border-radius: 0px;
  margin-bottom: 2.5vh;
  padding-right: 1.8em;
  padding-bottom: 1.4em;
  background-color: #FAFBFC;
}
.textinput1 {
  color: #000000;
  width: 30vw;
  font-size: 2vh;
  font-family: Circular Std Book;
  padding-top: 1.4em;
  border-color: #e6e7eb;
  border-width: 0.15vw;
  padding-left: 1.8em;
  border-radius: 0px;
  margin-bottom: 2.5vh;
  padding-right: 1.8em;
  padding-bottom: 1.4em;
  background-color: #FAFBFC;
}
.textinput2 {
  color: #000000;
  width: 14vw;
  font-size: 2vh;
  font-family: Circular Std Book;
  padding-top: 1.4em;
  border-color: #e6e7eb;
  border-width: 0.15vw;
  margin-right: 2vw;
  padding-left: 1.8em;
  border-radius: 0px;
  margin-bottom: 2.5vh;
  padding-right: 1.8em;
  padding-bottom: 1.5em;
  background-color: #FAFBFC;
}
.textinput3 {
  color: #000000;
  width: 14vw;
  font-size: 2vh;
  font-family: Circular Std Book;
  padding-top: 1.4em;
  border-color: #e6e7eb;
  border-width: 0.15vw;
  padding-left: 1.8em;
  border-radius: 0px;
  margin-bottom: 2.5vh;
  padding-right: 1.8em;
  padding-bottom: 1.4em;
  background-color: #FAFBFC;
}
.button {
  color: #ffffff;
  width: 30vw;
  font-size: 2vh;
  font-style: normal;
  margin-top: 1em;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: 400;
  padding-top: 1.6em;
  border-width: 0px;
  padding-left: 1.8em;
  border-radius: 0px;
  padding-right: 1.8em;
  padding-bottom: 1.6em;
  background-color: #0075ff;
}
.container7 {
  flex: 0 0 auto;
  width: 82vw;
  display: flex;
  align-self: center;
  margin-top: 5vh;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.text2 {
  font-size: 2.5vh;
  font-family: Circular Std Book;
}
.image7 {
  height: 3.8vh;
  object-fit: cover;
  margin-left: 0.6vw;
  margin-right: 0.6vw;
}
.text3 {
  font-size: 2.5vh;
  font-family: Circular Std Book;
}
@media(max-width: 479px) {
  .image {
    display: none;
  }
  .container1 {
    width: 82%;
    height: 12vh;
  }
  .image1 {
    height: 8vw;
  }
  .image2 {
    right: 0px;
    width: auto;
    height: 4vh;
    margin: auto;
    display: flex;
    position: absolute;
  }
  .container2 {
    display: none;
  }
  .container3 {
    display: none;
  }
  .container4 {
    margin-top: 8vh;
  }
  .text {
    font-size: 3vh;
  }
  .image6 {
    height: 4.5vh;
  }
  .text1 {
    font-size: 3vh;
  }
  .form {
    width: 82vw;
  }
  .textinput {
    width: 82vw;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .textinput1 {
    width: 82vw;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .textinput2 {
    width: 38vw;
    padding-top: 1em;
    margin-right: 6vw;
    padding-bottom: 1em;
  }
  .textinput3 {
    width: 38vw;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .button {
    width: 82vw;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
  }
  .container7 {
    margin-top: 8vh;
    justify-content: center;
  }
}

:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-unit: 1rem;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-space-space-unit: 1rem;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-halfunit: 0.5rem;
  --dl-radius-radius-round: 50%;
  --dl-size-size-doubleunit: 2rem;
  --dl-size-size-tripleunit: 3rem;
  --dl-space-space-halfunit: 0.5rem;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-doubleunit: 2rem;
  --dl-space-space-tripleunit: 3rem;
}
.thqLink {
  font-family: Arial;
  text-decoration: underline;
}
.thqButton {
  color: var(--dl-color-gray-black);
  padding: 5px 10px;
  font-family: Arial;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.thqTextInput {
  color: var(--dl-color-gray-black);
  font-family: Arial;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 2px;
  background-color: var(--dl-color-gray-white);
}
.thqTextArea {
  color: var(--dl-color-gray-black);
  font-family: Arial;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 2px;
  background-color: var(--dl-color-gray-white);
}
.content {
  font-size: 16px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.heading {
  font-size: 32px;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
